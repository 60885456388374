<template>
    <div>
        <div class="credit-body container-fluid">
            <div class="credit-body-title">クレジットカード決済ソリューション</div>
            <span>01</span>
            <div class="credit-body-desc">
                <div style="max-width: 540px;">
                <div class="credit-body-block">
                    <div>
                        <img src="@/assets/images/credit/img_xinyongka1.png" class="credit-body-img" alt="...">
                    </div>
                    <div>
                        <p class="credit-body-desc-desc">
                            金融分野の中でもクレジットカードのカード発行業務に必要な統合環境に関する分析・改善から構築・導入までのサービスを提供しています。また、現在クレジットカード業界で求められている「顧客志向」「異業種・異分野などを参考にした革新的な柔軟性」「収益性」といった要件に応えるコンサルティング作業は、数十年日本、中国で携わってきた金融分野での経験と最先端技術を備えたソリューションを「ソリューションサービスチーム」が担当致します。
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
         <div class="credit-body container-fluid" style="background:#FFF">
            <div class="credit-body-title">カード決済システム構築の機能イメージ</div>
            <span>02</span>
            <div class="credit-body-desc">
                <div style="max-width: 540px;">
                <div class="credit-body-block">
                    <div style="background-color:#FFF;z-index:2">
                        <img src="@/assets/images/credit/img_xinyongka2.png" class="credit-body-img-2" alt="...">
                    </div>
                    <div class="credit-body-desc-container-2 z-index:1">
                         <p class="credit-body-desc-title-2">
                            開発チーム体制:
                        </p>
                        <p class="credit-body-desc-desc-2">
                            プロジェクト規模を問わず、システム開発をチーム単位で設計・製造・検証・導入・保守運用までの作業を行います。※1チームの単位は3~5名程度までが目安
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="credit-body container-fluid">
            <div class="credit-body-title">開発方式</div>
            <span>03</span>
            <div class="credit-body-desc-3">
                <div class="credit-body-desc-title-3">
                    マイクロサービス開発
                </div>
                <div class="credit-body-desc-desc-3" >
                    <div>
                        <div style="width:25px;border-bottom:4px solid #0262FF;margin-top:20px;"></div>
                        <p>システムをサービス「サービス=機能」ごとに構築し、それらを複雑で高度な連係によってつなぎ合わせた一つのシステムである。</p>
                        <p>それにより、頻繁な変更や機能追加をすばやく対応が可能となる。開発手法は開発→利用→問題点のフィードバック→改良を繰り返しながら、システムを練り上げていくアジャイル方を採用により、アプリケーションをモジュール型構造にする必要がある。</p>
                        <p>これで、ビジネスの変化に即時対応が可能となる。</p>
                    </div>
                    <div>
                        <img src="@/assets/images/credit/img_xinyongka3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
         <div class="credit-body container-fluid" style="background:#FFF">
            <div class="credit-body-title">テクニカル リソース マップ</div>
            <span>04</span>
            <div class="credit-body-desc">
                <div class="credit-04-container" style="display:flex">
                    <div style="font-size:18px;
                    font-family:Noto Serif JP,YuGothic,serif;
                    font-weight:500;
                    color:rgba(51,51,51,1);
                    line-height:25px;">技術分野別構成要員分布図</div>
                    <div style="font-size:14px;margin-left:32px;
                    font-family:Noto Serif JP,YuGothic,serif;;
                    font-weight:400;
                    color:rgba(153,153,153,1);
                    line-height:20px;">(日常の様々な分野でお客様を技術で支援しています)</div>
                </div>
                <div class="credit-body-desc-desc-4">
                    <div>
                        <ul class="list-group">
                            <li class="list-group-item active">
                                <div>技術の種類</div>
                                <div>人数</div>
                            </li>
                            <li class="list-group-item">
                                <div>webアプリケーション設計製造</div>
                                <div>16</div>
                            </li>
                            <li style="background:rgba(3,98,255,0.04);" class="list-group-item">
                                <div>インフラストラクチャos、サーバ、ストレージ設計構造</div>
                                <div>2</div>
                            </li>
                            <li class="list-group-item">
                                <div>データベース関連技術</div>
                                <div>2</div>
                            </li>
                            <li style="background:rgba(3,98,255,0.04);" class="list-group-item">
                                <div>ネットワーク基礎技術</div>
                                <div>1</div>
                            </li>
                            <li class="list-group-item">
                                <div>PMP取得者（オブジェクト管理ツール）</div>
                                <div>1</div>
                            </li>
                            <li style="background:rgba(3,98,255,0.04);" class="list-group-item">
                                <div>プロジェクト管理</div>
                                <div>4</div>
                            </li>
                        </ul>
                    </div>
                    <div class="credit-circle">
                        <div>
                             <img src="@/assets/images/credit/img_xinyongka4.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .credit-body{
        background-color: #F5F5F9;
        padding: 0 calc(100vw/1920*360);
        padding-bottom: 40px;
        z-index: 1;
    }
    .credit-body-title{
        font-size:calc(100vw/1920*24);
        padding-top: 45px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:600;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .credit-body-block{
        display:flex;
        align-items:center;
    }
    .credit-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .credit-body-img{
        width: calc(100vw/1920*460);
    }
    .credit-body-desc-desc{
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        width:calc(100vw/1920*810) !important;
        background: #FFF;
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        text-align: left;
        padding: 24px;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*32);
        margin-left: -70px;
    }
    .credit-body-img-2{
        width: calc(100vw/1920*687);
        height: calc(100vw/1920*400);
        padding: 32px 20px;
        z-index: 2;
    }
    .credit-body-desc-title-2{
        line-height:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*25);
        text-align: left;
        padding:0 24px;
        margin: 0;
    }
    .credit-body-desc-desc-2{
        width: calc(100vw/1920*513);
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        text-align: left;
        padding: 24px;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*32);
        margin: 0;
    }
    .credit-body-desc-container-2{
        min-width: calc(100vw/1920*900);
        height: calc(100vw/1920*500);
        background-color: #0262FF;
        margin-left: calc(-100vw/1920*387);
        padding-left: calc(100vw/1920*387);
        padding-top: 50px;
    }
    .credit-body-desc-3{
        /* width:1200px; */
        /* height:480px; */
        padding: 30px 40px 50px 30px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .credit-body-desc-title-3{
        text-align: left;
        font-size:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
        
    }
    .credit-body-desc-desc-3{
        display: flex;
        justify-content: space-between;
        /* margin-top: 20px; */
    }
    .credit-body-desc-desc-3 p{
        font-size:calc(100vw/1920*16);;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*32);;
        margin-top: 30px;
        text-align: left;
        width: calc(100vw/1920*500);
    }
    .credit-body-desc-desc-3 img{
        width: calc(100vw/1920*604);
        height: calc(100vw/1920*380);
    }
    .credit-body-desc-desc-4{
        display: flex;
        margin-top: 40px;
    }
    .credit-body-desc-desc-4 li{
        width: calc(100vw/1920*710);
        padding-right: calc(100vw/1920*84);
        padding-left: calc(100vw/1920*27);
        display: flex;
        justify-content: space-between;
    }
    .credit-body-desc-desc-4 li div{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        line-height:22px;
    }
    .list-group-item{
        border: unset;
    }
    .list-group{
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-radius:8px 8px 0px 0px;
        border:1px solid rgba(3,98,255,0.4);
        height: calc(100vw/1920*460);
    }
    .credit-circle{
        width: calc(100vw/1920*460);
        height: calc(100vw/1920*460);
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-radius:8px;
        margin-left: 30px;
    }
    .credit-circle img{
        width: calc(100vw/1920*460);
        height: calc(100vw/1920*460);
    }
}
@media screen and (max-width:768px){
    .credit-body{
        background-color: #F5F5F9;
        padding: 20px;
        padding-bottom: 40px;
        z-index: 1;
    }
    .credit-body-title{
        font-size:14px;
        /* padding-top: 45px; */
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .credit-body>span{
        width:89px;
        height:89px;
        font-size:40px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .credit-body-img{
        width: 87vw;
    }
    .credit-body-desc-desc{
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        /* width:calc(100vw/1920*810) !important; */
        background: #FFF;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        text-align: left;
        padding: 24px;
        color:rgba(51,51,51,1);
        line-height:14px;
        /* margin-left: -70px; */
    }
    .credit-body-img-2{
        width: 90vw;
        /* height: calc(100vw/1920*400); */
        padding: 32px 20px;
        z-index: 2;
    }
    .credit-body-desc-title-2{
        line-height:16px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        text-align: left;
        padding:0 24px;
        margin: 0;
    }
    .credit-body-desc-desc-2{
        /* width: 200px; */
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        text-align: left;
        padding: 24px;
        color:rgba(255,255,255,1);
        line-height:14px;
        margin: 0;
    }
    .credit-body-desc-container-2{
        /* min-width: calc(100vw/1920*900); */
        /* height: calc(100vw/1920*500); */
        background-color: #0262FF;
        /* margin-left: -60px; */
        /* padding-left: 60px; */
        padding-top: 50px;
    }
    .credit-body-desc-3{
        /* width:1200px; */
        /* height:480px; */
        padding: 10px 15px 25px 10px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .credit-body-desc-title-3{
        text-align: left;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:14px;
        
    }
    .credit-body-desc-desc-3{
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        /* margin-top: 20px; */
    }
    .credit-body-desc-desc-3 p{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
        margin-top: 30px;
        text-align: left;
        /* width: calc(100vw/1920*500); */
    }
    .credit-body-desc-desc-3 img{
        margin-top: 5vw;
        width: 84vw;
    }
    .credit-body-desc-desc-4{
        /* display: flex; */
        margin-top: 40px;
    }
    .credit-body-desc-desc-4 li{
        /* width: calc(100vw/1920*710); */
        /* padding-right: calc(100vw/1920*84); */
        /* padding-left: calc(100vw/1920*27); */
        display: flex;
        justify-content: space-between;
    }
    .credit-body-desc-desc-4 li div{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        line-height:14px;
        text-align: left;
    }
    .list-group-item{
        border: unset;
    }
    .list-group{
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-radius:8px 8px 0px 0px;
        border:1px solid rgba(3,98,255,0.4);
    }
    .credit-circle{
        /* width: calc(100vw/1920*460); */
        /* height: calc(100vw/1920*460); */
        /* display: flex; */
        margin-top: 20px;
        align-items: center;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-radius:8px;
        /* margin-left: 30px; */
    }
    .credit-circle img{
        width:90vw;
        /* height: calc(100vw/1920*460); */
    }
    .credit-04-container{
        display: unset !important;
    }
}
@media screen and (min-width:1920px){
    .credit-body{
        background-color: #F5F5F9;
        padding: 0 360px;
        padding-bottom: 40px;
        z-index: 1;
    }
    .credit-body-block{
        display:flex;
        align-items:center;
    }
    .credit-body-title{
        font-size:24px;
        padding-top: 45px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .credit-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .credit-body-img{
        width: 460px;
    }
    .credit-body-desc-desc{
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        width:810px !important;
        background: #FFF;
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        text-align: left;
        padding: 24px;
        color:rgba(51,51,51,1);
        line-height:32px;
        margin-left: -70px;
    }
    .credit-body-img-2{
        width: 687px;
        height: 400px;
        padding: 32px 20px;
        z-index: 2;
    }
    .credit-body-desc-title-2{
        line-height:18px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:25px;
        text-align: left;
        padding:0 24px;
        margin: 0;
    }
    .credit-body-desc-desc-2{
        width: 513px;
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        text-align: left;
        padding: 24px;
        color:rgba(255,255,255,1);
        line-height:32px;
        margin: 0;
    }
    .credit-body-desc-container-2{
        min-width: 900px;
        height: 500px;
        background-color: #0262FF;
        margin-left: 387px;
        padding-left: 387px;
        padding-top: 50px;
    }
    .credit-body-desc-3{
        /* width:1200px; */
        /* height:480px; */
        padding: 30px 40px 50px 30px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .credit-body-desc-title-3{
        text-align: left;
        font-size:18px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:25px;
        
    }
    .credit-body-desc-desc-3{
        display: flex;
        justify-content: space-between;
        /* margin-top: 20px; */
    }
    .credit-body-desc-desc-3 p{
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:32px;
        margin-top: 30px;
        text-align: left;
        width: 500px;
    }
    .credit-body-desc-desc-3 img{
        width: 604px;
        height: 380px;
    }
    .credit-body-desc-desc-4{
        display: flex;
        margin-top: 40px;
    }
    .credit-body-desc-desc-4 li{
        width: 710px;
        padding-right: 84px;
        padding-left: 27px;
        display: flex;
        justify-content: space-between;
    }
    .credit-body-desc-desc-4 li div{
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        line-height:22px;
    }
    .list-group-item{
        border: unset;
    }
    .list-group{
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-radius:8px 8px 0px 0px;
        border:1px solid rgba(3,98,255,0.4);
        height: 460px;
    }
    .credit-circle{
        width: 460px;
        height: 460px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-radius:8px;
        margin-left: 30px;
    }
    .credit-circle img{
        width: 460px;
        height: 460px;
    }
}
</style>